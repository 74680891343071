/****** MEDIA QUERIES ******

0    - 760px  :   xs
761  - 1024px  :  sm
1025 - 1440px :   md
1441 - 1920px :   lg (default)
>1920px       :   xl

1em = 16px (default browser font-size)

$breakpoint args: xs, sm, md, lg, xl

ORDER: base > typography > general layout (header, footer, nav, grid) > page-specific layout > components

******/
@import url("https://fonts.googleapis.com/css?family=Inconsolata:400,700|Playfair+Display:700,400i&display=swap");
.u-container-full {
  width: 100%;
  margin: 0 auto; }

.u-container-full-padding {
  width: calc(100% - 80px);
  margin: 0 auto; }
  @media only screen and (max-width: 90em) {
    .u-container-full-padding {
      width: calc(100% - 64px); } }
  @media only screen and (max-width: 64em) {
    .u-container-full-padding {
      width: calc(100% - 32px); } }
  @media only screen and (max-width: 47.5em) {
    .u-container-full-padding {
      width: calc(100% - 20px); } }

.u-container-fullheight-padding {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 80px);
  margin-top: 40px; }
  @media only screen and (max-width: 90em) {
    .u-container-fullheight-padding {
      height: calc(100vh - 64px);
      margin-top: 32px; } }
  @media only screen and (max-width: 64em) {
    .u-container-fullheight-padding {
      height: calc(100vh - 32px);
      margin-top: 16px; } }
  @media only screen and (max-width: 47.5em) {
    .u-container-fullheight-padding {
      height: calc(100vh - 20px);
      margin-top: 10px; } }

.u-container-fixed {
  width: 1280px;
  margin: 0 auto; }
  @media only screen and (max-width: 90em) {
    .u-container-fixed {
      width: 896px; } }
  @media only screen and (max-width: 64em) {
    .u-container-fixed {
      width: 704px; } }
  @media only screen and (max-width: 47.5em) {
    .u-container-fixed {
      width: calc(100% - 40px); } }

.u-container-56 {
  width: 56%; }
  @media only screen and (max-width: 47.5em) {
    .u-container-56 {
      width: 100%;
      text-align: center; } }

.u-container-40 {
  width: 40%; }
  @media only screen and (max-width: 47.5em) {
    .u-container-40 {
      margin-left: 0;
      width: 100%; } }

.u-container-60 {
  width: 60%; }
  @media only screen and (max-width: 64em) {
    .u-container-60 {
      width: 80%;
      margin-left: auto;
      margin-right: auto; } }
  @media only screen and (max-width: 47.5em) {
    .u-container-60 {
      width: 100%; } }

.u-container-75 {
  width: 75%; }
  @media only screen and (max-width: 64em) {
    .u-container-75 {
      width: 100%; } }

.u-container-85 {
  width: 85%; }
  @media only screen and (max-width: 64em) {
    .u-container-85 {
      width: 100%; } }

.u-centered {
  margin-left: auto;
  margin-right: auto; }

.u-text-center {
  text-align: center; }

.u-extra-margin-top {
  margin-top: 8rem; }

.u-margin-top-xsmall {
  margin-top: 1rem; }

.u-margin-top-small {
  margin-top: 3rem; }

.u-margin-top-med {
  margin-top: 5rem; }

.u-margin-bottom-xsmall {
  margin-bottom: 1rem !important; }

.u-grid-asymmetric {
  display: grid;
  grid-template-columns: 22% 78%;
  grid-template-rows: auto;
  grid-template-areas: "icon heading" "icon body" "table table"; }
  @media only screen and (max-width: 47.5em) {
    .u-grid-asymmetric {
      grid-template-columns: 28% 72%;
      grid-template-areas: "icon heading" "body body" "table table";
      row-gap: 1rem; } }

.u-grid-symmetric {
  display: grid;
  grid-template-columns: 44% 44%;
  column-gap: 12%;
  grid-template-rows: auto;
  grid-template-areas: "img img" "body body" "col col"; }
  @media only screen and (max-width: 47.5em) {
    .u-grid-symmetric {
      grid-template-columns: 100%;
      grid-template-areas: "img" "body" "col";
      row-gap: .5rem; } }

.u-flex {
  display: flex;
  align-items: flex-start; }
  @media only screen and (max-width: 64em) {
    .u-flex {
      flex-direction: column; } }

.u-external-link {
  position: relative; }
  .u-external-link:after {
    content: "";
    display: inline-block;
    width: 1.5rem;
    height: 1em;
    background-image: url(/img/icon-external-link.svg);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: .8em; }

.u-list-checklist {
  list-style-image: url(/img/icon-checked.svg);
  padding-left: 1.3em; }
  .u-list-checklist li {
    padding-bottom: .5em; }

.u-align-right {
  text-align: right; }

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-size: 125%; }
  @media only screen and (max-width: 90em) {
    html {
      font-size: 112.5%; } }
  @media only screen and (max-width: 64em) {
    html {
      font-size: 100%; } }

/**
   * Make all elements from the DOM inherit from the parent box-sizing
   * Since `*` has a specificity of 0, it does not override the `html` value
   * making all elements inheriting from the root box-sizing value
   * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
   */
*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  padding: 0;
  margin: 0; }

a {
  text-decoration: none; }

strong {
  font-weight: 700; }

::selection {
  color: #ffffff;
  background-color: #F95D62; }

img {
  max-width: 100%; }

body {
  font-family: 'Inconsolata', monospace;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: #575757; }

.heading-hero {
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  font-size: 3rem;
  line-height: .9;
  margin-top: 0;
  margin-bottom: .5em; }
  @media only screen and (max-width: 64em) {
    .heading-hero {
      font-size: 2.6rem; } }
  @media only screen and (max-width: 47.5em) {
    .heading-hero {
      font-size: 2.2rem; } }

.heading-primary {
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  font-size: 1.8rem;
  margin-top: 0;
  margin-bottom: .5em; }
  @media only screen and (max-width: 47.5em) {
    .heading-primary {
      font-size: 1.4rem; } }

.heading-secondary {
  font-size: .9rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: .1em;
  margin-bottom: .5em;
  color: #F95D62; }
  .heading-secondary.on-dark-bg {
    color: #FCE5E5; }

.heading-tertiary {
  font-size: .7rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: .1em;
  margin-bottom: .5em;
  color: #575757; }

.paragraph {
  margin-top: 0;
  margin-bottom: 1.5rem; }
  .paragraph.on-dark-bg {
    color: #ffffff; }

a:link, a:visited {
  color: #5DB0F9;
  transition: color .2s; }

a:hover, a:active {
  color: #575757; }

strong {
  font-weight: 700; }

.tags {
  font-size: .8rem;
  display: inline-block;
  background-color: rgba(87, 87, 87, 0.4);
  color: #ffffff;
  padding-left: .5em;
  padding-right: .5em;
  margin-right: 0em; }

.small {
  font-size: .7rem; }

.header {
  width: 100%;
  border-top-color: #F95D62;
  border-top-style: solid;
  border-top-width: 6px;
  padding-top: 2rem;
  padding-bottom: 4rem; }
  @media only screen and (max-width: 90em) {
    .header {
      border-top-width: 5px; } }
  @media only screen and (max-width: 64em) {
    .header {
      border-top-width: 4px; } }
  @media only screen and (max-width: 47.5em) {
    .header {
      padding-top: 1rem;
      border-top-width: 3px; } }
  .header__logo {
    width: 10rem; }
    @media only screen and (max-width: 47.5em) {
      .header__logo {
        width: 8rem; } }
  .header__content {
    display: flex;
    justify-content: space-between; }

@media only screen and (max-width: 47.5em) {
  .navigation {
    position: fixed;
    background-color: #575757;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    transform: translateX(100%);
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center; } }

@media only screen and (max-width: 47.5em) {
  .navigation__list {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    padding-bottom: 6rem; } }

.navigation__link {
  margin-right: 1.8rem;
  margin-left: 1.8rem;
  position: relative; }
  @media only screen and (max-width: 64em) {
    .navigation__link {
      margin-right: 1.2rem;
      margin-left: 1.2rem; } }
  @media only screen and (max-width: 47.5em) {
    .navigation__link {
      margin-right: 0;
      margin-left: 0;
      margin-top: .4em;
      margin-bottom: .4em; } }
  .navigation__link:link, .navigation__link:visited {
    color: rgba(87, 87, 87, 0.7); }
    @media only screen and (max-width: 47.5em) {
      .navigation__link:link, .navigation__link:visited {
        color: #ffffff;
        font-size: 1.6rem;
        text-align: center; } }
  .navigation__link::after {
    content: "";
    position: absolute;
    bottom: -.1em;
    left: -5%;
    display: inline-block;
    width: 0%;
    height: .45em;
    z-index: -1;
    background-color: #5DB0F9;
    opacity: .7;
    transition: all .2s ease-in; }
  .navigation__link:hover {
    color: #575757; }
    @media only screen and (max-width: 47.5em) {
      .navigation__link:hover {
        color: #FCE5E5; } }
    .navigation__link:hover::after {
      width: 110%; }
      @media only screen and (max-width: 47.5em) {
        .navigation__link:hover::after {
          width: 0%; } }

.navicon {
  width: 1.5rem;
  height: 1.5rem;
  position: fixed;
  top: 1.5em;
  right: 20px;
  z-index: 1000;
  display: none; }
  @media only screen and (max-width: 47.5em) {
    .navicon {
      display: block; } }
  .navicon__button {
    width: 1.5rem;
    height: 1.5rem;
    position: fixed;
    cursor: pointer; }
  .navicon__icon {
    position: relative;
    margin-top: .75rem; }
    .navicon__icon, .navicon__icon::before, .navicon__icon::after {
      width: 1.5rem;
      height: .125rem;
      background-color: #F95D62;
      display: block;
      transition: all .2s; }
    .navicon__icon::before, .navicon__icon::after {
      content: "";
      position: absolute;
      left: 0; }
    .navicon__icon::before {
      top: -.5rem; }
    .navicon__icon::after {
      top: .5rem; }
  .navicon__button:hover .navicon__icon::before {
    top: -.625rem; }
  .navicon__button:hover .navicon__icon::after {
    top: .625rem; }
  .navicon__button.on .navicon__icon {
    background-color: transparent; }
  .navicon__button.on .navicon__icon::before {
    top: 0;
    transform: rotate(135deg); }
  .navicon__button.on .navicon__icon::after {
    top: 0;
    transform: rotate(-135deg); }

.navbar {
  width: 100%;
  height: 3.4rem;
  background-color: rgba(87, 87, 87, 0.9);
  color: #ffffff;
  position: fixed;
  top: -3.4rem;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: top .3s ease-in; }
  @media only screen and (max-width: 47.5em) {
    .navbar {
      display: none; } }
  .navbar__wrapper {
    display: flex;
    justify-content: space-between; }
  .navbar__logo {
    height: 1.5rem;
    align-self: center; }
  .navbar__list {
    align-self: center; }
  .navbar__link {
    margin-left: 1.8rem;
    margin-right: 1.8rem; }
    .navbar__link:link, .navbar__link:visited {
      color: #ffffff; }

.footer {
  position: relative; }
  .footer__content {
    background-color: #F95D62;
    padding-top: 10rem;
    padding-bottom: 8rem;
    position: absolute;
    top: -9rem;
    z-index: -1; }
    @media only screen and (max-width: 47.5em) {
      .footer__content {
        padding-top: 6rem;
        padding-bottom: 4rem;
        top: -5rem; } }
    .footer__content a {
      color: #FCE5E5; }
  .footer__body {
    color: #ffffff;
    font-size: .8rem;
    text-align: center;
    width: 60%;
    margin: 0 auto; }
    @media only screen and (max-width: 90em) {
      .footer__body {
        width: 75%; } }
    @media only screen and (max-width: 64em) {
      .footer__body {
        width: 80%; } }
    @media only screen and (max-width: 47.5em) {
      .footer__body {
        width: 100%; } }

.button {
  display: inline-block;
  padding: .4em 1.2em;
  margin-right: 1em;
  text-transform: uppercase;
  font-size: .9rem;
  letter-spacing: .1em;
  min-width: 7rem;
  text-align: center; }
  .button__primary:link, .button__primary:visited {
    color: #ffffff;
    background-color: #F95D62;
    transition: background-color .2s; }
  .button__primary:hover {
    background-color: #5DB0F9; }
  .button__secondary:link, .button__secondary:visited {
    color: #F95D62;
    border: 1px solid #F95D62;
    transition: all .2s; }
  .button__secondary:hover {
    border-style: dashed; }

.table__row {
  display: table-row; }

.table__cell {
  display: table-cell;
  width: 78%;
  border-top: 1px dashed #F95D62;
  border-bottom: 1px dashed #F95D62;
  padding-top: .5em;
  padding-bottom: .5em; }
  .table__cell:first-child {
    width: 22%; }
    @media only screen and (max-width: 47.5em) {
      .table__cell:first-child {
        width: 28%; } }

input, textarea, button {
  font: inherit; }

input {
  width: 100%;
  padding: .2em 2%;
  margin-bottom: .5em;
  border: 1px dashed #F95D62; }

textarea {
  color: #575757;
  width: 100%;
  height: 12rem;
  padding: .2em 2%;
  margin-bottom: .5em;
  border: 1px dashed #F95D62; }

button {
  padding: .4em 1.6em;
  text-transform: uppercase;
  font-size: .9rem;
  letter-spacing: .1em;
  text-align: center;
  color: #ffffff;
  background-color: #F95D62;
  border: none;
  float: right;
  cursor: pointer;
  transition: background-color .2s; }
  button:hover {
    background-color: #5DB0F9; }

.form-success {
  display: flex;
  width: 75%;
  height: 75%;
  margin: 0 auto; }
  @media only screen and (max-width: 47.5em) {
    .form-success {
      flex-direction: column-reverse; } }
  .form-success__content {
    padding-top: 4rem;
    align-self: center; }
  .form-success__img-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .form-success__img {
    width: 80%;
    margin-left: 15%; }
    @media only screen and (max-width: 47.5em) {
      .form-success__img {
        margin-left: 0; } }

.section {
  padding-top: 6rem;
  padding-bottom: 8rem; }
  @media only screen and (max-width: 47.5em) {
    .section {
      padding-top: 2rem;
      padding-bottom: 4rem; } }
  .section__hero {
    background-color: #FCE5E5;
    margin-top: 12rem; }
    @media only screen and (max-width: 47.5em) {
      .section__hero {
        margin-top: 4rem; } }
  .section__grey {
    background-color: #575757; }
  .section__pink {
    background-color: #FCE5E5; }
  .section__contact {
    background-color: #FCE5E5;
    margin-top: 40px; }
    @media only screen and (max-width: 90em) {
      .section__contact {
        margin-top: 32px; } }
    @media only screen and (max-width: 64em) {
      .section__contact {
        margin-top: 16px; } }
    @media only screen and (max-width: 47.5em) {
      .section__contact {
        margin-top: 10px; } }

.hero__wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: -8rem; }
  @media only screen and (max-width: 90em) {
    .hero__wrapper {
      margin-bottom: -4rem; } }
  @media only screen and (max-width: 64em) {
    .hero__wrapper {
      margin-bottom: 0; } }
  @media only screen and (max-width: 47.5em) {
    .hero__wrapper {
      flex-direction: column-reverse;
      margin-bottom: 4rem; } }

.hero__content {
  margin-top: -11.4rem;
  padding-bottom: 6rem; }
  @media only screen and (max-width: 64em) {
    .hero__content {
      margin-top: -10.68rem; } }
  @media only screen and (max-width: 47.5em) {
    .hero__content {
      margin-top: -4.2rem;
      padding-bottom: 0; } }

.hero__img {
  width: 100%;
  transform: translateY(calc(-48% - 4rem)); }
  @media only screen and (max-width: 47.5em) {
    .hero__img {
      transform: translateY(-48%);
      max-width: 24rem; } }

.work__wrapper {
  margin-bottom: 9rem; }
  @media only screen and (max-width: 47.5em) {
    .work__wrapper {
      margin-bottom: 3rem; } }

.work__img-wrapper {
  width: 40%;
  margin-top: 4rem;
  text-align: center; }
  @media only screen and (max-width: 64em) {
    .work__img-wrapper {
      margin-top: 0;
      margin-left: 10%;
      width: 80%;
      text-align: left; } }
  @media only screen and (max-width: 47.5em) {
    .work__img-wrapper {
      width: 100%;
      margin-left: 0; } }

.work__img {
  width: 90%; }
  @media only screen and (max-width: 90em) {
    .work__img {
      width: 100%; } }
  @media only screen and (max-width: 64em) {
    .work__img {
      width: 78%;
      margin-left: 22%; } }
  @media only screen and (max-width: 47.5em) {
    .work__img {
      width: 90%;
      margin-left: 5%; } }

.work__testimonial {
  font-family: 'Playfair Display', serif;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 4rem;
  padding-top: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 1.6rem;
  font-weight: 400;
  font-style: italic;
  color: #888888; }
  @media only screen and (max-width: 47.5em) {
    .work__testimonial {
      font-size: 1.2rem; } }
  .work__testimonial:before {
    content: "\201c";
    font-family: 'Playfair Display', serif;
    font-weight: 700;
    font-style: normal;
    font-size: 12rem;
    line-height: 1;
    color: #FCE5E5;
    position: absolute;
    top: -1.5rem;
    left: -1rem;
    z-index: -1; }
    @media only screen and (max-width: 47.5em) {
      .work__testimonial:before {
        font-size: 8rem;
        top: 0; } }
  .work__testimonial:after {
    content: "\201d";
    font-family: 'Playfair Display', serif;
    font-weight: 700;
    font-style: normal;
    font-size: 12rem;
    line-height: 0;
    color: #FCE5E5;
    position: absolute;
    bottom: -2rem;
    right: 0;
    z-index: -1; }
    @media only screen and (max-width: 47.5em) {
      .work__testimonial:after {
        font-size: 8rem;
        bottom: -1rem; } }
  .work__testimonial .person {
    font-family: 'Inconsolata', monospace;
    font-size: .9rem;
    font-style: normal;
    text-align: right; }

.intro__content {
  display: flex; }

.section__content {
  width: 60%;
  margin-left: auto;
  margin-right: auto; }
  @media only screen and (max-width: 90em) {
    .section__content {
      width: 75%; } }
  @media only screen and (max-width: 64em) {
    .section__content {
      width: 80%; } }
  @media only screen and (max-width: 47.5em) {
    .section__content {
      width: 100%; } }

.section__icon {
  width: 124px;
  grid-area: icon; }
  @media only screen and (max-width: 90em) {
    .section__icon {
      width: 100px; } }
  @media only screen and (max-width: 64em) {
    .section__icon {
      width: 4.7rem; } }
  @media only screen and (max-width: 47.5em) {
    .section__icon {
      width: 75%;
      max-width: 4.7rem; } }

.section__img {
  width: 80%;
  transform: translateY(calc(-60% - 4rem));
  grid-area: img;
  justify-self: center;
  margin-bottom: -8rem; }
  @media only screen and (max-width: 47.5em) {
    .section__img {
      max-width: 24rem; } }

.section__heading {
  grid-area: heading;
  margin-bottom: 1rem; }
  @media only screen and (max-width: 47.5em) {
    .section__heading {
      align-self: center; } }

.section__body {
  grid-area: body; }

.section__card {
  margin-bottom: 1rem; }
  @media only screen and (max-width: 47.5em) {
    .section__card {
      margin-bottom: 0; } }

.section__table {
  width: 100%;
  grid-area: table;
  display: table;
  border-collapse: collapse; }
